.backgroundVision {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: fixed;

    background-image: url(../../Assets/Images/visionbackground.jpg);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.backgroundBlur {
    width: 100%;
    height: 100vh;
    position: fixed;

    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 2;
}

.darkGradient {
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 0;
    background-color: black;
    z-index: 2;
}

.rmBlur {
    backdrop-filter: blur(0) opacity(1) !important;
    -webkit-backdrop-filter: blur(0) opacity(1) !important;
    z-index: 999;
    background-color: black;
}

.poemText {
    position: absolute;
    text-align: center;
    z-index: 3;
    margin-top: 100vh;
    width: 100%;
}