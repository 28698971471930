.gallerySection {
    max-width: 600px;
    // min-height: 100vh;
    // padding-top: 13vh;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h1 {
        // margin-bottom: -12px !important;
    }

    h2 {
        // margin-bottom: -10px !important;
    }
}

.galleryGrid {
    margin: 20px 0;

    width: 100%;
    height: auto;
}