.videoContainer {
    // overflow: hidden;
    width: 100%;
    height: 100vh;
    align-content: center;

    scroll-snap-align: start;
    scroll-snap-stop: always;
}

video {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: 1px solid rgba(252, 241, 225, 0.1);
    // border-bottom: 1px solid red;
}

@media (max-width: 768px) {
    .videoContainer {
        display: none;
    }
}