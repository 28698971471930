h1 {
    font-family: "Provicali", sans-serif !important;
    font-size: 62px !important;
    margin: 0 0 10px 0 !important;
    text-transform: uppercase;
    line-height: 80px !important;
}

h2 {
    font-family: "Provicali", sans-serif !important;
    font-size: 22px !important;
    margin: 0 !important;
    text-transform: uppercase;
}

h3 {
    font-size: 16px !important;
    margin: 3px 0 0 0 !important;
}

p {
    font-weight: 500;
    font-size: 17px;
}

@media (max-width: 768px) {
    h1 {
        font-size: 48px !important;
        margin: 0 0 5px 0 !important;
    }

    h2 {
        font-size: 17px !important;
        margin: 0 !important;
    }

    h3 {
        font-size: 12px !important;
        margin: 3px 0 0 0 !important;
    }

    p {
        font-size: 14px;
    }
}

.homeContainer {
    // height: 100vh;
    width: 90%;
    margin: 0 auto;
    align-items: center;
}

.section {
    max-width: 600px;
    min-height: 100vh;
    padding-top: 10vh;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    scroll-snap-align: center;
    scroll-snap-stop: normal;
}

.sectionAbout {
    max-width: 600px;
    min-height: 75vh;
    padding-top: 10vh;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    scroll-snap-align: center;
    scroll-snap-stop: normal;
}

.otherNFT {
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    transition: .2s;
}

.homeNFT {
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    transition: .2s;

    opacity: 0;
    transform: scale(.9);
}

.homeNFTMain {
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100px;
    z-index: -1;
    transition: .2s;

    opacity: 1;
}

// Features

.sectionFeatures {
    max-width: 600px;
    min-height: 100vh;
    // padding-top: 10vh;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.featureGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    // gap: 15px;
    margin-top: 15px;
}

.featureContainer {
    text-align: center;
    width: 33%;
    // padding: 20px;

    p {
        font-size: 14px;
    }
}

/* @media (max-width: 768px) {
    .featureContainer {
         p {
            display: none;
        } 
    }
} */

.featureImage {
    width: 100%;
    padding: 25px;
}

// Join The Streak

.btnMint {
    font-weight: 600 !important;
    font-size: 14px !important;
    display: inline-block;
    transition: 0.2s !important;
    -webkit-transition: 0.2s !important;
    max-width: 200px;
    z-index: 1;
    padding: 10px !important;
    color: #fcf1e1 !important;
    background: #2425FF !important;
    border-radius: 0 !important;

    margin-top: 5px;
    text-transform: uppercase;

    min-width: 100px;
}

.btnMint:hover {
    border: 1px solid #fcf1e1;
}

// Team
.teamContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    margin-top: 15px;
    width: 90%;
}

.teamInfo {
    display: none;
    position: absolute;
    top: 10%;
    margin: 0 10px;
    // text-align: center;
}

.memberText {
    margin: 0 !important;
    font-size: 14px !important;
    transition: .2s;
}

.twitterHandle {
    font-size: 14px !important;
    color: #FCF1E1;
}

@media (max-width: 768px) {
    .teamContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        margin-top: 15px;
        width: 100%;

        max-width: 500px;
    }

    .teamInfo {
        display: none;
        position: absolute;
        top: 5px;
        margin: 0;
        padding: 10px;
        // text-align: center;
    }

    .memberText {
        margin: 0 !important;
        font-size: 12px !important;
        transition: .2s;
    }

    .twitterHandle {
        font-size: 12px !important;
        color: #FCF1E1;
    }
}

@media (max-width: 460px) {
    .teamCard:hover {
        .teamInfo {
            display: none !important;
        }
    }

    .teamImage {
        filter: none !important;
        -webkit-filter: none !important;
        -moz-filter: none !important;
        -o-filter: none !important;
        -ms-filter: none !important;
    }
}

.teamImage {
    width: 100%;
    position: relative;
    object-fit: cover;

    transition: .3s;
}

.teamCard {
    position: relative;
    overflow: hidden;
    background-color: black;
    outline: 2px solid #5E5E5E;

    position: relative;
    z-index: 1;
}

.teamCard:hover {
    cursor: pointer;
    z-index: 10;
    position: relative;

    .teamInfo {
        display: block;
        animation: fadeIn .3s;
    }

    .twitterHandle:hover {
        color: #FCF1E1;
    }

    .teamImage {
        // transform: scaleX(1.2);

        filter: blur(5px);
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.teamCardText {
    padding: 15px;

    h3 {
        text-transform: uppercase;
    }
}

// Follow
.followContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;

    margin-top: 15px;
    gap: 10px;
}

.followCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #282828;
    width: 100%;
    transition: .2s;

    color: #FCF1E1;
    text-decoration: none;
}

.followCard:hover {
    background-color: #fcf1e1;
    cursor: pointer;
    color: #1A1B1B;
}

.followImage {
    width: 100%;
    max-width: 100px;
    margin-right: 15px;
}

@media (max-width: 768px) {
    .followContainer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;

        margin-top: 15px;
        gap: 10px;
    }

    .followImage {
        width: 100%;
        max-width: 100px;
        margin-right: 20px;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 16px !important;
    }
}

// FAQ
.faqStyle {
    .accordionContainer {
        margin-top: 15px;
    }

    .accordion-item {
        background-color: transparent;
        border: none;
        color: #fcf1e1;

        font-family: "Montserrat", sans-serif !important;
        font-weight: 500;
        font-size: 17px;

        background-color: transparent;
        border-radius: 0;

        //margin-bottom: 15px;
    }

    .accordion-button {
        background-color: transparent;
        border: 1px solid #fcf1e1;
        color: #fcf1e1;
        border-radius: 0;
        padding: 20px;

        font-family: "Montserrat", sans-serif !important;
        font-weight: 600 !important;
        font-size: 17px;
    }

    .accordion-button:not(.collapsed) {
        color: black;
        box-shadow: none;
        border: none;
        outline: none;

        background-color: #fcf1e1;
        border-radius: 0;
    }

    .accordion-body {
        padding: 15px 20px 20px 20px;
    }

    .faqHeader {
        margin-bottom: 0;
    }

    .accordion {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .well {
        margin: 0 !important;
    }

    .accordion-button:active,
    .accordion-button:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid #fcf1e1;
    }

    .accordion-button:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }

    @media (max-width: 768px) {
        .accordion-button:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }
    }

    @media (max-width: 515px) {
        .accordion-button:after {
            margin-left: auto;
        }

        .accordion-button:not(.collapsed)::after {
            margin-left: auto;
        }
    }
}

// Scroll Magic 

.showNFT {
    opacity: 1;
    transform: scale(1);
}

@media (max-width: 840px) {

    .showNFT,
    .otherNFT {
        opacity: .5;
    }

    .homeNFTMain {
        height: 950px;
        opacity: .5;
        right: -200px;
    }
}

@media (max-width: 1400px) {

    .showNFT,
    .otherNFT {
        opacity: .5;
    }
}

.homeNFTMain {
    display: none;
}

@media (max-width: 768px) {
    .hideMobile {
        display: none;
    }

    .showMobile {
        display: block;
    }

    .homeNFTMain {
        display: block;
    }
}