#navbar {
    position: relative;
    z-index: 999;

    .container-fluid {
        padding: 0 !important;
    }

    .navlogo {
        width: 70px;
        height: auto;
        // background-color: black !important;
    }

    /* .navLogoContainer {
        transition: .2s;
        border-radius: 300px;
    }

    .navLogoContainer:hover {
        background: linear-gradient(147.18deg, #B72ED9 5.64%, #1C14F5 50.02%, #7BFBFB 91.71%);
    } */

    .bg-custom {
        padding: 8px 0;
        // background: linear-gradient(180deg, rgba($color: #8D68FF, $alpha: .1) 0%, rgba($color: #8D68FF, $alpha: 0) 60%);
        transition: 0.3s;
        // background-color: rgba(5, 5, 5, .3);
        border-bottom: 1px solid rgba(252, 241, 225, 0.1);
        backdrop-filter: blur(8px) opacity(1);
    }

    .navbarWidth {
        width: 90%;
        margin: 0 auto;
        // max-width: 1600px;
    }

    .nav-link {
        color: #FCF1E1 !important;
        font-size: 13px;
        font-weight: 600 !important;
        text-transform: uppercase;
        padding: 8px 12px;
        border-radius: 2px;
    }

    .nav-link:hover {
        color: #FCF1E1 !important;
        background-color: rgba(252, 241, 225, 0.3);
    }

    .useActive {
        .active {
            color: #FCF1E1 !important;
            background-color: rgba(252, 241, 225, 0.3);
        }
    }

    .dashboardButton {
        font-weight: 700 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 9px 20px !important;
        color: #fcf1e1;
        background: linear-gradient(302.04deg, #9870FF 2.07%, #AC87FF 73.72%);
        border-radius: 15px;
    }

    .dashboardButton:hover {
        color: #fcf1e1;
        transform: scale(1.05);
    }

    .navIcon {
        margin: 0 15px;
    }
}

@media (max-width: 768px) {
    #navbar {
        .navbarWidth {
            width: 90%;
            margin: 0 auto;
            // max-width: 1400px;
        }

        .bg-custom {
            padding: 8px 0;
        }

        .navlogo {
            width: 60px;
            height: auto;
            // margin-right: 20px;
        }

        /* .nav-link {
            padding-left: 0;
        } */

        .nav-link:hover {
            background-color: transparent;
        }

        .current {
            padding-left: 18px;
        }

        .navIcon:first-child {
            margin: 0 15px 0 0;
        }

        .socialIcons {
            margin-top: 10px;
        }
    }
}