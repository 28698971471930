.buyNFT {
    height: 91vh;
    width: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: .2s;

    // outline: 1px solid rgba(252, 241, 225, 0.1);
    outline: none;
    pointer-events: none;
}

@media (max-width: 1400px) {
    .buyNFT {
        height: 91vh;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: .2s;
        opacity: .5;
    }
}

@media (max-width: 840px) {
    .buyNFT {
        height: 90vh;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: .2s;
        opacity: .5;
    }
}

.sectionBuy {
    max-width: 600px;
    // min-height: 100vh;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;

    // scroll-snap-align: center;
    // scroll-snap-stop: normal;
}

.timelineImage {
    margin-bottom: 15px;
}

.twoCol {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 15px 0;
}

.intervalButton {
    font-family: "Provicali", sans-serif !important;
    font-size: 26px;
    color: black;
    background-color: #fcf1e1;
    outline: none;
    border: none;

    // padding: 0px 10.7px;
    width: 41px;
}

.mintForm {
    font-family: "Montserrat", sans-serif !important;
    font-size: 22px;
    text-align: center !important;
    text-transform: uppercase;
    color: #fcf1e1 !important;
    opacity: 1;

    outline: 0;
    border: none;
    padding: 0 !important;
    margin: 0 0 0 28px !important;
    background: transparent;

    // width: 65px;
    // position: relative;
    // top: 3px;
    padding-left: 15px;
}

.mintForm::placeholder {
    font-family: "Montserrat", sans-serif !important;
    font-size: 22px;
    text-align: left;
    text-transform: uppercase;
    color: #fcf1e1 !important;
}