.Bluebackground {
    background-color: #002583;
    min-height: 100vh;

    background: url(../../Assets/Images/blueprintbackground.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;

    padding-bottom: 20px;
}

/* .textBorder {
    -webkit-text-stroke: 2px black;
} */

.blueContainer {
    // height: 100vh;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    padding-top: 15vh;
}

.bluePrintGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    grid-auto-flow: row dense;

    margin-top: 0px;
}

.large {
    grid-column: auto / span 1;
    grid-row: auto / span 2;
}

.medium {
    grid-column: auto / span 2;
    grid-row: auto / span 1;
}

.blueprintContainer {
    position: relative;
    overflow: hidden;
    padding: 20px;
    backdrop-filter: blur(10px);
    min-height: 200px;
    color: #fcf1e1;
    text-decoration: none;
    transition: .4s;

    // background: url(../../Assets/Images/ethtrace.png), rgba($color: #1a1b1b, $alpha: .8);
    // background-size: 80%;
    // background-repeat: no-repeat;
    // background-position: right;
    // opacity: .8;


    // color: black;
    background-color: rgba(7, 22, 166, .7);
    border: 2px solid transparent;
}

.blueprintContainer:before {
    content: ' ';
    display: block;
    position: absolute;
    right: -40px;
    top: -20px;
    width: 350px;
    height: 350px;
    opacity: 0.5;
    // background-image: url('../../Assets/Images/ethtrace.png');
    background-repeat: no-repeat;
    background-size: 400px;
    transition: .3s;
}

.blueprintContainer:nth-child(1):before {
    background-image: url('../../Assets/Images/ethtrace.png');
}

.blueprintContainer:nth-child(1):hover:before {
    background-image: url('../../Assets/Images/ethcolor.png');
}


.blueprintContainer:nth-child(2):before {
    background-image: url('../../Assets/Images/frametrace.png');
}

.blueprintContainer:nth-child(2):hover:before {
    background-image: url('../../Assets/Images/framecolor.png');
}


.blueprintContainer:nth-child(3):before {
    background-image: url('../../Assets/Images/wandtrace.png');
}

.blueprintContainer:nth-child(3):hover:before {
    background-image: url('../../Assets/Images/wandcolor.png');
}

.blueprintContainer:nth-child(4):before {
    background-image: url('../../Assets/Images/zooshtrace.png');
    width: 550px;
    height: 550px;
    background-size: 550px;
    right: -140px;
    top: -50px;
}

.blueprintContainer:nth-child(4):hover:before {
    background-image: url('../../Assets/Images/zooshcolor.png');
}


.blueprintContainer:nth-child(5):before {
    background-image: url('../../Assets/Images/shiptrace.png');

    right: -30px;
    top: -60px;
}

.blueprintContainer:nth-child(5):hover:before {
    background-image: url('../../Assets/Images/shipcolor.png');
}


.blueprintContainer:nth-child(6):before {
    background-image: url('../../Assets/Images/beyondtrace.png');
    width: 550px;
    height: 550px;
    background-size: 550px;
}

.blueprintContainer:nth-child(6):hover:before {
    background-image: url('../../Assets/Images/beyondcolor.png');
}

.blueprintContainer:hover:before {
    opacity: 1;
}

.blueprintContainer:hover {
    color: #fcf1e1;
    border: 2px solid white;
    cursor: pointer;
}

.backBtn {
    margin-bottom: 5px;
    font-weight: 600;

    text-decoration: none;
    color: #fcf1e1;
}

.backBtn:hover {
    margin-bottom: 5px;
    font-weight: 600;

    text-decoration: none;
    color: #fcf1e1;
}

@media (max-width: 768px) {
    .bluePrintGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .large {
        grid-column: unset;
        grid-row: unset;
    }

    .medium {
        grid-column: unset;
        grid-row: unset;
    }

    .blueprintContainer:before {
        content: ' ';
        display: block;
        position: absolute;
        right: -110px;
        top: -20px;
        width: 300px;
        height: 300px;
        opacity: 0.35;
        // background-image: url('../../Assets/Images/ethtrace.png');
        background-repeat: no-repeat;
        background-size: 300px;
        transition: .3s;
    }

    .blueprintContainer:nth-child(4):before {
        background-image: url('../../Assets/Images/zooshtrace.png');
        width: 350px;
        height: 350px;
        background-size: 350px;
        right: -120px;
        top: -30px;
    }

    .blueprintContainer:nth-child(5):before {
        background-image: url('../../Assets/Images/shiptrace.png');

        right: -100px;
        top: 10px;
    }

    .blueprintContainer:nth-child(6):before {
        background-image: url('../../Assets/Images/beyondtrace.png');
        width: 300px;
        height: 300px;
        background-size: 300px;
        top: 10px;
        right: -110px;
    }
}