@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "Provicali";
  font-weight: 700;
  src: local("Provicali"),
    url(./Assets/fonts/Provicali.otf) format("OpenType");
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
  color: #FCF1E1 !important;
  overflow-x: hidden;

  // scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
  // scroll-snap-type: y mandatory;
  overflow-x: none;
}

code {
  font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
p {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
}

a {
  text-decoration: none !important;
}