.detailedGrid {
    display: grid;
    grid-template-columns: 1.4fr .6fr;
    gap: 50px;

    background-color: rgba($color: #002583, $alpha: .9);
    backdrop-filter: blur(10px);
    padding: 35px;
}

.detailedImage {
    width: 100%;
    height: auto;
    margin-top: 30px;

    // transform: rotate(8deg);
}

.detailedImage2 {
    width: 470px;
    height: auto;
    margin-top: 30px;
    position: absolute;
    right: 0;

    // transform: rotate(8deg);
}

.detailedimgContainer {
    text-align: right;
}

@media (max-width: 1080px) {
    .detailedGrid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 25px;
    }

    .detailedImage {
        width: 100%;
        height: auto;
        margin-top: 0;

        // transform: rotate(0);
    }

    .detailedImage2 {
        position: unset;
        width: 100%;
        height: auto;
        margin-top: 0;

        // transform: rotate(0);
    }
}